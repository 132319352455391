/* src/styles.css */

/* Reset default margin and padding */
body,
html,
#root {
  margin: 0;
  padding: 0;
  font-family: "Segoe UI", sans-serif;
}

/* Set a background color for the body */
body {
  background-color: #f7f7f7;
  font-family: "Segoe UI", sans-serif;
  color: #333;
  transition: background-color 0.05s, color 0.05s;
}

.container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  transition: background-color 0.05s, color 0.05s;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  transition: background-color 0.05s;
  text-align: left;
}

.title-logo {
  align-items: center;
  display: flex;
}

.header h1 {
  font-size: 24px;
}

.header-buttons {
  display: flex;
  align-items: center;
  border: none;
  padding: 7px 4px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.btn-signin,
.btn-night-mode,
.btn-github,
.btn-about {
  background-color: #1da1f2;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;
}

.btn-signout {
  background-color: #ff4500;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
  margin-left: 10px;
}

.tweet-container {
  background-color: #fff;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.tweet-input {
  width: 100%;
  resize: none;
  border: none;
  background-color: #f7f7f7;
  color: #333;
  font-size: 16px;
  padding: auto;
  margin-bottom: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-family: "Segoe UI", sans-serif;
}

.btn-tweet {
  background-color: #1da1f2;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.tweet {
  background-color: #fff;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.tweet-content {
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: 500;
}

.tweet-user {
  font-size: 14px;
  color: #888;
}

.user-and-time {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tweet-time {
  font-size: 10px;
  text-align: right;
  color: #88888892;
}

/* Night mode styles */
.night-mode {
  background-color: #000000;
  color: #ffffff;
}

.night-mode .header h1,
.night-mode .tweet-content,
.night-mode .tweet-user {
  color: #ffffff;
}

.night-mode .tweet-user {
  color: #888;
}

.night-mode .btn-signin,
.night-mode .btn-signout,
.night-mode .btn-night-mode,
.night-mode .btn-github,
.night-mode .tweet-container,
.night-mode .tweet {
  background-color: #343a40;
  box-shadow: 0 2px 4px rgba(255, 255, 255, 0.1);
}

.night-mode .tweet-input {
  background-color: #222;
  color: #ffffff;
}

.night-mode .header-buttons {
  display: flex;
  align-items: center;
  border: none;
  padding: 7px 4px;
  border-radius: 5px;
  background-color: #222;
  box-shadow: 0 2px 4px rgba(255, 255, 255, 0.148);
}

.btn-night-mode,
.btn-signin,
.btn-signout,
.btn-github {
  transition: background-color 0.05s;
}

.tweet-container,
.tweet,
.tweet-input {
  transition: background-color 0.05s, box-shadow 0.05s;
}

/* Icons */
.fa-sun:before {
  content: "\f185";
}

.fa-moon:before {
  content: "\f186";
}

/* Font Awesome */
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css");

/* Add animations */
.tweet-enter {
  opacity: 0;
  transform: translateY(20px);
}

.tweet-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms, transform 300ms;
}

.tweet-exit {
  opacity: 1;
}

.tweet-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}

.btn-github {
  padding: 8px 16px;
  margin-right: 10px;
}

.btn-github a {
  color: #fff;
  text-decoration: none;
}

/* ... (previous styles) */

.tweet-container {
  display: flex;
  flex-direction: column;
}

.tweet-options {
  display: flex;
  margin-top: 10px;
}

.tweet-checkbox {
  font-size: 10px;
  color: #888;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.tweet-checkbox input {
  margin-right: 5px;
  appearance: none;
  width: 18px;
  height: 18px;
  border: 2px solid #555;
  border-radius: 4px;
  outline: none;
  transition: border-color 0.3s ease-in-out;
}

.tweet-checkbox input:checked {
  border-color: #1da1f2;
}

.tweet-checkbox input::before {
  content: "\f00c"; /* Font Awesome checkmark icon */
  font-family: "Font Awesome 5 Free";
  display: inline-block;
  font-weight: bold;
  font-size: 14px;
  color: #1da1f2;
  visibility: hidden;
  transition: visibility 0.2s ease-in-out;
}

.tweet-checkbox input:checked::before {
  visibility: visible;
}

/* ... (previous styles) */

.tweet {
  position: relative;
  border-radius: 5px;
  margin: 1rem 1;
  background-color: #fff;
}

.tweet-content {
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

.user-and-time {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #888;
}

.tweet-menu {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.tweet-menu-button {
  padding: 0.5rem;
  border: none;
  background-color: transparent;
  color: #888;
  font-size: 1.2rem;
  cursor: pointer;
  transition: color 0.3s;
}

.tweet-menu-button:hover {
  color: #ff4f4f;
}

/* ... (previous styles) */

/* Add styles for the Twitter icon */
.twitter-logo {
  font-size: 36px;
  margin-right: 10px;
  
}

/* Styles for the night mode */
.night-mode .twitter-logo {
  color: #fff; /* Change icon color to white in night mode */
}

/* Existing styles for day mode */

.change-log {
  background-color: #fff;
  border-radius: 8px;
  padding: 16px;
  padding-top: 0.1px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: 30px;
}

.change-log h2 {
  margin-bottom: 10px;
}

.change-log-item {
  margin-bottom: 20px;
}

.change-log-item h3 {
  margin-bottom: 5px;
}

.change-log-item ul {
  padding-left: 20px;
}

.change-log-item li {
  margin-bottom: 5px;
}

/* Night mode styles */
.night-mode .change-log {
  background-color: #343a40;
  box-shadow: 0 2px 4px rgba(255, 255, 255, 0.1);
}

.night-mode .change-log h2 {
  color: #fff;
}

.night-mode .change-log-item h3 {
  color: #ddd;
}

.night-mode .change-log-item li {
  color: #ddd;
}

.delete-message {
  font-size: 10px;
  color: #8888887d;
  margin-left: 10px;
  text-align: right;
}

.tweet-box {
  align-items: baseline;
  display: flex;
  justify-content: space-between;
}
